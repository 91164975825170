<template>
  <v-container class="pa-0">
    <v-row class="no-gutters elevation-0">
      <v-col cols="auto" class="flex-grow-1 flex-shrink-0">
        <v-responsive class="custom-chat-height-wrapper">
          <v-card class="d-flex flex-column fill-height">
            <v-card-title class="chat-header">
              <label>
                <span>{{ $t('vinSearchBox.title') }}</span>
              </label>
            </v-card-title>
            <v-card-text
              class="pa-0 flex-grow-1 overflow-y-auto custom-chat-height"
            >
              <div class="pa-4">
                <div class="py-2">
                  <ApiErrorMessage :message="apiErrorMessage" />
                </div>
                <v-form class="mr-3">
                  <v-card class="pa-0 d-flex align-center" flat>
                    <v-text-field
                      v-model="vin"
                      class="mr-3"
                      elevation="0"
                      height="60"
                      dense
                      :label="$t('vinSearchBox.input')"
                      solo
                    ></v-text-field>
                    <v-btn
                      medium
                      light
                      min-height="60"
                      class="green darken-4 green-gradient white--text"
                      elevation="0"
                      @click="search"
                    >
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </v-card>
                </v-form>
              </div>
            </v-card-text>
          </v-card>
        </v-responsive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";

export default {
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  data() {
    return {
      apiErrorMessage: null,
      vin: null
    }
  },
  methods: {
    ...mapActions({
      getVinSearchReport: "vinSearchReport/getVinSearchReport"
    }),

    async search() {
      this.$store.commit("setLoader", true);
      try {
        await this.getVinSearchReport({vin: this.vin});
        this.$router.push('/dashboard/vin-search-result');
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
  },
};
</script>